import React from 'react'
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../includes/route-names';

const CycleCommunity = (props) => {
    return (
        <section className="rider_sec animation-element">
            <div className="container">
                <div className="tophead"> 
                    <h2 className="title1">
                        Tinder for the Bike World
                    </h2>
                    <p>Bringing The Cycling Community Together</p>
                </div>
                <div className="ride_panel">
                    <div className="riderow clear">
                        <div className="ridedivision ridedivision1">

                            <div className="ridediv">
                                <Link to={RouteNames.smart_solution}>
                                 data-fancybox="gallery" data-caption="Online Bike Retailers and Offline Shops">
                                    <img src={require('../../../assets/images/ride1.jpg')} alt=""/>
                                </Link>
                                <div className="overlay">
                                    <div className="text">
                                        <h4>Find the Smart Solutions to bike smarter and improve your performance</h4> 
                                    </div> 
                                    <span className="design"></span>
                                </div>
                                <span className="hd">Find the Smart Solutions to bike smarter and improve your performance</span>
                            </div>
                        </div>
                        <div className="ridedivision ridedivision2">
                            <div className="ridedivisionch">
                                <div className="ridediv">
                                    <Link to={RouteNames.buy}>
                                        <img src={require('../../../assets/images/ride2.jpg')} alt=""/>
                                    </Link>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>Smart Bikers of today find the latest and best Products</h4>
                                            <p>Bikes or Accessories, Retailers, Brands in your region. Explore why you should choose the brand.</p>
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">Smart Bikers of today find the latest and best Products</span>
                                </div>
                                <div className="ridediv">
                                    <Link to={RouteNames.services}>
                                        <img src={require('../../../assets/images/ride3.jpg')} alt=""/>
                                    </Link>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>Find the relevant Service Providers</h4>
                                            <p>Renting, Repairing, Training, Insurance and more</p>
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">Find the relevant Service Providers</span>
                                </div>
                            </div>
                            <div className="ridedivisionch">
                                <div className="ridediv">
                                    <Link to={RouteNames.smart_solution}>
                                        <img src={require('../../../assets/images/ride4.jpg')} alt=""/>
                                    </Link>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>A platform where Start-Ups and Business can find Solutions Developers, Designers and Integrators</h4>
                                            
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">A platform where Start-Ups and Business can find Solutions Developers, Designers and Integrators</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="riderow clear">
                        <div className="ridedivision ridedivision3">
                            <div className="ridediv">
                                <Link to={RouteNames.bikeEnthusticList}>
                                    <img src={require('../../../assets/images/ride5.jpg')} alt=""/>
                                </Link>
                                <div className="overlay">
                                    <div className="text">
                                        <h4>Brands and Corporates find Influencers, Bike Enthusiasts, Cycling Clubs or Ambassadors</h4>
                                       
                                    </div> 
                                    <span className="design"></span>
                                </div>
                                <span className="hd">Brands and Corporates find Influencers, Bike Enthusiasts, Cycling Clubs or Ambassadors</span>
                            </div>
                            <div className="ridediv">
                                <Link to={RouteNames.event}>
                                    <img src={require('../../../assets/images/ride6.jpg')} alt=""/>
                                </Link>
                                <div className="overlay">
                                    <div className="text">
                                        <h4>Bikers looking for Cycling partners, Coaches, Guides for City Tours and Weekend Gateways</h4>
                                         
                                    </div> 
                                    <span className="design"></span>
                                </div>
                                <span className="hd">Bikers looking for Cycling partners</span>
                            </div>
                        </div>
                        <div className="ridedivision ridedivision4">
                            <div className="ridediv">
                                <Link to={RouteNames.biking}>
                                    <img src={require('../../../assets/images/ride7.jpg')} alt=""/>
                                </Link>
                                <div className="overlay">
                                    <div className="text">
                                        <h4>Tourists Discover Bike Destinations</h4>
                                        <p>Tourist offices and Hotels providing biking activities</p>
                                    </div> 
                                    <span className="design"></span>
                                </div>
                                <span className="hd">Tourists Discover Bike Destinations</span>
                            </div>
                        </div>
                    </div>


                    <div className="riderow clear">
                        <div className="ridedivision ridedivision1">
                                <div className="ridediv">
                                    <Link to={RouteNames.buy}>
                                        <img src={require('../../../assets/images/ride10.jpg')} alt="" className="cyclee-img"/>
                                    </Link>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>Companies looking for Distributors, Wholesalers, Dealers, Resellers, Online Retailers</h4>
                                            
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">Companies looking for Distributors, Wholesalers, Dealers, Resellers, Online Retailers</span>
                                </div>
                        </div>
                        <div className="ridedivision ridedivision2">
                            <div className="ridedivisionch">
                                <div className="ridediv">
                                    <Link to={RouteNames.buy}>
                                        <img src={require('../../../assets/images/ride9.jpg')} alt=""/>
                                    </Link>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>Manufacturers looking for Suppliers</h4> 
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">Manufacturers looking for Suppliers</span>
                                </div>
                                <div className="ridediv">
                                    <Link to={RouteNames.services}>
                                        <img src={require('../../../assets/images/ride11.jpg')} alt=""/>
                                    </Link>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>Find your repair Buddy</h4> 
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">Find your repair Buddy</span>
                                </div> 
                            </div>
                            <div className="ridedivisionch">
                            <div className="ridediv">
                                <Link to={RouteNames.bikeEnthusticList}>
                                    <img src={require('../../../assets/images/ride8.jpg')} alt=""/>
                                </Link>
                                <div className="overlay">
                                    <div className="text">
                                        <h4>Casual and Professional Bikers looking for Trainers and Coaches</h4> 
                                    </div> 
                                    <span className="design"></span>
                                </div>
                                <span className="hd">Casual and Professional Bikers looking for Trainers and Coaches</span>
                            </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="riderow clear">
                        <div className="ridedivision ridedivision1 ridedivision1-2">
                            <div className="ridediv">
                                <a href={require('../../../assets/images/ride8.jpg')} data-fancybox="gallery" data-caption="Daily Commuters">
                                    <img src={require('../../../assets/images/ride8.jpg')} alt=""/>
                                </a>
                                <div className="overlay">
                                    <div className="text">
                                        <h4>Casual and Professional Bikers looking for Trainers and Coaches</h4> 
                                    </div> 
                                    <span className="design"></span>
                                </div>
                                <span className="hd">Casual and Professional Bikers looking for Trainers and Coaches</span>
                            </div>
                            <div className="ridediv">
                                <a href={require('../../../assets/images/ride9.jpg')} data-fancybox="gallery" data-caption="Cycle Clubs">
                                    <img src={require('../../../assets/images/ride9.jpg')} alt=""/>
                                </a>
                                <div className="overlay">
                                    <div className="text">
                                        <h4>Manufacturers looking for Suppliers</h4> 
                                    </div> 
                                    <span className="design"></span>
                                </div>
                                <span className="hd">Manufacturers looking for Suppliers</span>
                            </div>
                        </div>
                        <div className="ridedivision ridedivision2"> 
                                <div className="ridediv">
                                    <a href={require('../../../assets/images/ride10.jpg')} data-fancybox="gallery" data-caption="Service Provider finds someone to deliver Pizza">
                                        <img src={require('../../../assets/images/ride10.jpg')} alt=""/>
                                    </a>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>Companies looking for Distributors, Wholesalers, Dealers, Resellers, Online Retailers</h4>
                                            
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">Companies looking for Distributors, Wholesalers, Dealers, Resellers, Online Retailers</span>
                                </div>
                                <div className="ridediv">
                                    <a href={require('../../../assets/images/ride11.jpg')} data-fancybox="gallery" data-caption="Casual Riders">
                                        <img src={require('../../../assets/images/ride11.jpg')} alt=""/>
                                    </a>
                                    <div className="overlay">
                                        <div className="text">
                                            <h4>Find your repair Buddy</h4> 
                                        </div> 
                                        <span className="design"></span>
                                    </div>
                                    <span className="hd">Find your repair Buddy</span>
                                </div> 
                            
                        </div>
                    </div> */}
                </div> 
            </div>
        </section>
    )
}

export default CycleCommunity;
